<template>
    <div class="row">
        <div class="col-md-12">
            <vue-cal @click="sacarmes()" :snapToTime="30" min-event-width="50" sticky-split-labels
                :min-split-width="minevent" :locale="local" @view-change="prueba" v-model:active-view="vistaactiva"
                :dblclick-to-navigate="clickparanavegar" :split-days="splitDays" events-count-on-year-view
                :disableViews="disabledViews" timeCellHeight="50" ref="vuecal"
                :timeFrom="((propiedadescalendario.planning_hora_ini)*60)-60"
                :timeTo="((propiedadescalendario.planning_hora_fin)*60)+60" todayButton="true"
                :events-on-month-view="false" :events-on-week-view="[true, 'short']" hide-content-bar
                @event-duration-change="confirmarevento($event,'redimensiona')" :on-event-click="onEventClick"
                :events="eventos" @event-drop="confirmarevento($event,'arrastra')" :editable-events="editable"
                @event-delete="eliminarVisita($event)" :selected-date="selectedDate">
                <template v-slot:event="{ event }">
                    <span @mouseover="mostrar($event,event)" class="vuecal__flex vuecal__cell-content"
                        :style='"background-color:" + event.color + ";border:1px solid #000;color:black" +"display:block"'>
                        <div style="text-align:center"
                            v-if="event.title == 'GUARDIA' || event.title == 'OCUPADO' || event.title == 'BLOQUEADO' || event.title == 'VACACIONES'">
                            {{event.title}}
                        </div>
                        <div v-else style="font-size:0.8em">
                            <!-- {{ decode_utf8(event.content.substring(0, event.content.indexOf(pepe))) }} -->
                            {{ decode_utf8(event.poblacion) }}&nbsp;<span v-if="event.cp"
                                :style="'background-color:'+event.color_km">({{ event.cp }})</span>
                        </div>
                    </span>
                </template>
                <template #split-label="{ split }">
                    <table>
                        <tr>
                            <td style="line-height: 12px;">
                                <!--<strong :style="`color: ${split.color}`">{{ split.label.toString().toUpperCase() }}</strong></td></tr>-->
                                <i v-if="split.autovisita==1" title="Operario configurado para Autovisita"
                                    class="fas fa-regular fa-lightbulb" :style="`color: rgb(225, 225, 1)`"></i>
                                &nbsp;<strong>{{ split.label.toString().toUpperCase() }}</strong>
                            </td>
                        </tr>
                        <tr v-if="split.preferente == 1">
                            <td>
                                <strong>[preferente]</strong>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:center">
                                <i v-if="split.observaciones" :title="' Observaciones\n'+split.observaciones"
                                    class="pi pi-exclamation-triangle" :style="`color: #f00`"></i>
                                <!--<strong :title="$t('general.Carga')" :style="`color: ${split.color}`">[{{ split.carga }}%]</strong>-->
                                <strong :title="$t('general.Carga')">[{{ split.carga }}%]</strong>
                                <a href="#" :title="$t('general.Llamar')" :onclick="split.clicktocall"><i
                                        class="pi pi-phone"></i></a>
                            </td>
                        </tr>
                    </table>
                </template>
                <template #title="{ title }">
                    <div :style="'color:black;' + colorfestivo">{{ title }}&nbsp;&nbsp;{{ nombrefestividad }}</div>
                </template>
            </vue-cal>
            <OverlayPanel ref="op" v-show="!noesguardiaofestivo" style="width:600px" :showCloseIcon="false"><!---->
                <p>{{ descripcionevento }}</p>
                <servicio-datos-visitas-visita :planning="1" :visita="eventoamostrar" v-if="eventoamostrar"
                    :colorbotondos="colorbotondos" :colorbotontres="colorbotontres" :colorbotoncuatro="colorbotoncuatro"
                    :colorboton="colorboton" :servicio="servicio" :id="id" :propsplanning="false" />
                <!-- <button type="button" v-show="eventoamostrar.visita && eventoamostrar.visita.confirmar==1"
                    @click="confirmarvisita(eventoamostrar)" title="Confirmar Visita" class="btn btn-default ">
                    <i class="fas fa-align-justify"></i>
                </button>
                <button type="button" class="btn btn-default " v-on:click='abrirventana' title="Parte Genérico">
                    <i class="far fa-file"></i>
                </button>
                <button type="button" class="btn btn-default " v-on:click="finalizarvisita()"
                    :style="'background:' + colorbotoncuatro" title="Finalizar Visita">
                    <i class="fas fa-check"></i>
                </button>

                <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                    <i class="fas fa-share-square"></i>
                </button>

                <ul class="dropdown-menu">
                    <li class="dropdown-item"><a href="#" @click="parteoperarioemail()">{{$t('general.enviarfichaemail')
                            }}</a></li>
                    <li class="dropdown-divider"></li>
                    <li class="dropdown-item"><a href="#" @click="smsoperario()">{{ $t('general.smsaloperario') }}</a>
                    </li>
                    <li class="dropdown-item"><a href="#" @click="smsasegurado()">{{ $t('general.smsalasegurado') }}</a>
                    </li>
                    <li class="dropdown-item"><a href="#" @click="steasegurado()">{{ $t('general.firmasteemail') }}</a>
                    </li>
                </ul>-->
            </OverlayPanel>
        </div>
    </div>
    <Dialog ref='dialog1' v-model:visible="visible" v-show="visible" modal :header="titulo" :style="{ width: altura }">
        <!--'50vw'-->
        <p v-text="infoevento"></p>
        <servicio-datos-visitas-visita :planning="1" :visita="eventoamostrar" v-if="eventoamostrar"
            :colorbotondos="colorbotondos" :colorbotontres="colorbotontres" :colorbotoncuatro="colorbotoncuatro"
            :colorboton="colorboton" :servicio="servicio" :id="id" />
        <div class="row" v-if="editar == true" style="background:#c7f08b61">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="ffinidos">{{ $t('general.FechaInicio') }}</label><br>
                    <Calendar id="ffinidos" v-model="fechainicioeditar" :stepMinute="10" showTime hourFormat="24"
                        dateFormat="dd/mm/yy" />
                    <div class="custom-control custom-checkbox"> <br>
                        <input v-model="nomodificar" class="custom-control-input" type="checkbox" id="nomodificar"
                            true-value="1" false-value="0">
                        <label for="nomodificar" class="custom-control-label">{{ $t('general.nomodificar') }}</label>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="fechafindos">{{ $t('general.fechafin') }}</label><br>
                    <Calendar id="fechafindos" v-model="fechafineditar" showTime :stepMinute="10"
                        dateFormat="dd/mm/yy" />
                    <div class="custom-control custom-checkbox"> <br>
                        <input v-model="prioritaria" class="custom-control-input" type="checkbox" id="prioritaria"
                            true-value="1" false-value="0">
                        <label for="prioritaria" class="custom-control-label">{{ $t('general.prioritaria') }}</label>
                    </div>
                </div><br><br>
                <Button
                    @click="modificarVisita(eventoamostrar,fechainicioeditar,fechafineditar,infoevento,nomodificar,prioritaria)">{{
                    $t('general.guardar') }}</Button>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="descripcion">{{ $t('general.descripcion') }}</label>
                    <span class="p-float-label">
                        <Textarea autoResize v-model="infoevento" class="descripcion" />
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                </div>
            </div>
        </div>
        <div class="row" v-if="finalizar == true">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header bg-gray">
                        <h3 class="card-title"><i class="fab fa-telegram-plane mr-2"></i>{{ $t('finalizarvisita') }}
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="trabajosrealizados">{{$t('trabajosrealizados')}}</label>
                                    <textarea v-model="trabajosrealizadostexto" class="form-control" rows="10" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="trabajospendientes">{{ $t('trabajospendientes') }}</label>
                                <textarea v-model="trabajospendientestexto" class="form-control" rows="10" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5">
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-success btn-block" @click="finVisita()" type="button">Finalizar
                                    Visita</button>
                            </div>
                            <div class="col-md-5">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="row"  v-if="editar == true" style="background:#c7f08b61"><div class="col-md-5"></div> <Button>{{ $t('general.guardar') }}</Button> </div>-->
        <planningcomunicaciones :id="eventoamostrar.visita.servicios_idservicios"
            :idvisita="eventoamostrar.visita.idvisitador" v-if="mostrarcomunicaciones" />
        <trabajosrealizadosoperarios v-if="eventoamostrar && trabajos" :operariosingular="operariosingular"
            :servicio="servicio" :totalrent="totalrent" :ocultarcliente="ocultarcliente"
            :ocultaroperario="ocultaroperario" :totalcliente="totalcliente" :totaloperario="totaloperario"
            :id="eventoamostrar.visita.servicios_idservicios" />
        <presupuestos v-if="presupuestos==true" :id="eventoamostrar.visita.servicios_idservicios" />
    </Dialog>
    <Dialog header="¿Quieres Modificar el evento?" :style="{ width: '25rem' }" ref='dialogconfirmacion'
        v-show="confirmacionvisible" v-model:visible="confirmacionvisible" modal>
        <div class="row">
            <div class="col-md-4"></div>
            <h5 style="text-align:center"><b>{{ eventoamodificar.event.class}}</b></h5>
        </div>
        <div class="row">
            <div class="col-md-2"></div>
            <p><br><b>Original: {{ new Date(eventoamodificar.originalEvent.start).addHours(1).toLocaleTimeString()
                    }}</b> hasta
                <b>{{ new
                    Date(eventoamodificar.originalEvent.end).addHours(1).toLocaleTimeString() }}</b><br><br><b>Nuevo: {{
                    new
                    Date(eventoamodificar.event.start).addHours(1).toLocaleTimeString() }}</b> hasta <b>{{ new
                    Date(eventoamodificar.event.end).addHours(1).toLocaleTimeString() }}</b>
            </p>
        </div>
        <div class="row">
            <p></p>
        </div>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-3">
                <button style="width:100%;background:green;border:0px"
                    @click="crearevento(eventoamodificar,tipoamodificar)" class=" btn btn-primary btn-sm">Si</button>
            </div>
            <div class="col-md-3">
                <button style="width:100%;background:red;border:0px" @click="confirmacionvisible = false;recargar()"
                    class="btn btn-primary btn-sm">Cancelar</button>
            </div>
        </div>
    </Dialog>
</template>
<script>
import serviciodatosvisitasvisita from '../servicios/datos/visitas/visita_datos.vue';
import Dialog from 'primevue/dialog';
import VueCal from 'vue-cal'; 
import Calendar from 'primevue/calendar';
import Presupuestos from '../servicios/presupuestos/presupuestos.vue';
import 'vue-cal/dist/vuecal.css';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import planningcomunicaciones from '../servicios/planning/planning_comunicaciones.vue';
import trabajosrealizadosoperarios from '../servicios/trabajosrealizados/TrabajosRealizadosOperario.vue';
import OverlayPanel from 'primevue/overlaypanel';
import { PwgsApi } from '../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio','operarios','operariosseleccionados','especioperario','cambios'],
    components: {
        Dialog, VueCal, Calendar,Textarea,Button, trabajosrealizadosoperarios, planningcomunicaciones,OverlayPanel,Presupuestos,
        'servicio-datos-visitas-visita': serviciodatosvisitasvisita, 
        
    },
    data() {
        return {
            mesactual:new Date().getMonth(),
            minevent: "150",
            titulo: '',
            eventoamostrar: '',
            nomodificar:'0',
            infoevento: '',
            confirmacionvisible:false,
            prioritaria:'0',
            editable: { title: false, drag: true, resize: true, delete: true, create: false },
            visible: false,
            miseventos: [],
            eventos: [],
            editar: false,
            local: 'es',
            color: ["blue", "green", "orange", "red", "purple", "yellow", "silver", "aqua", "maroon","lime", "navy", "fuchsia", "olive", "aqua"],
            vistaactiva: "day",
            colorboton: "normal",
            altura:'50vw',
            splitDays: [],
            anyoactual:'',
            aux: [],
            trabajosrealizadostexto: '',
            trabajospendientestexto:'',
            mostrarpop: false,
            fechainicioeditar: new Date(),
            fechafineditar: new Date(),
            selectedDate: new Date(),
            trabajos:false,
            nevisible: false,
            eventoacrear: new Date(),
            operarioseleccionado: '',
            especialidadseleccionada: '',
            fechafin: '',
            especialidadesdos: [],
            equipos: [],
            equiposanyadir:[],
            equiposseleccionado: [],
            clickparanavegar: true,
            descripcionvisitas: '',
            nombreseleccionado:'',
            clientedescripcion: [],
            nombreequipo: '',
            operariosingular: '',
            colorbotondos: "normal",
            colorbotontres: "normal",
            colorbotoncuatro: "normal",
            mostrarcomunicaciones: false,
            tipoamodificar:'',
            finalizar: false,
            presupuestos: false,
            operariossplit: [],
            diaactual: '',
            idservicioevento: '',
            idvisitaevento:'',
            propiedadescalendario:'',
            documentosrellenar: [],
            documentosanyadir: [],
            eventoamodificar:'',
            url: '',
            textoprueba: "hola",
            pepe: "(",
            mes: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            auxiliarsplit:[],
            inicio:true,
            diasemanal : '',
            messemanal : '',
            añosemanal: '',
            colorfestivo:'',
            nombrefestividad: '',
            noesguardiaofestivo: true,

            descripcionevento:'',
        }
    },
    computed:{
        disabledViews(){
            if(Array.isArray(this.operariosseleccionados) && this.operariosseleccionados.length > 0){                
                return ["years", "year"];
            }else{
                return ["years", "year", "week"];
            }
        }
    },
    methods: {
        cargar() {
            console.log('cargacarg');
            this.descripcionvisitas = '';
            this.eventos = [];
            this.aux = [];
            this.splitDays = [];
            this.local = localStorage.getItem("locale");
            if (this.local == "cat") { this.local = "ca"; }
            //this.cargareventos();
            this.cargarespecialidades();
            console.log('cargacarg2');
        },
        mostrar(evento, event) {
            if(event.title != 'GUARDIA' && event.title != 'OCUPADO' && event.title != 'BLOQUEADO' && event.title != 'VACACIONES' && event.title != 'RESERVA'){
            this.idservicioevento = event.idservicio;
            this.idvisitaevento = event.id_visita;
            this.cargarvisitaevento(event.idservicio, event.id);
                this.mostrarpop = true;
                this.descripcionevento = event.content;
                this.$refs.op.hide(evento); this.$refs.op.show(evento); this.$refs.op.alignOverlay(evento);
            }
        },
        confirmarevento(evento,tipo) {
            this.eventoamodificar = null;
            this.tipoamodificar = null;
            this.confirmacionvisible = true;
            this.eventoamodificar = evento;
            this.tipoamodificar = tipo;
        },
         async finalizarvisita() {
            this.$parent.$parent.$parent.toggleFinalizar();
        },
        agregarequipo() { 
            if(this.equipos.length>=1){
            let subidadatos = { nombre: this.nombreequipo, id_equipo: this.equiposseleccionado };
                this.equiposanyadir.push(subidadatos); 
            }
        },
        toggleTrabajos() {
            this.trabajos = !this.trabajos;
            if (this.trabajos == true) { this.altura = '80vw'; this.colorboton = "green" }
            else if (this.trabajos == false && this.editar == true && this.mostrarcomunicaciones == true || this.trabajos == false && this.editar == false && this.mostrarcomunicaciones == true || this.trabajos == false && this.editar == true && this.mostrarcomunicaciones == false ) { this.altura = '80vw'; this.colorboton = "normal" }
            else { this.altura = '50vw'; this.colorboton = "normal";}
        },
        toggleEditar() {
            this.editar = !this.editar;
            if (this.editar == true) { this.altura = '80vw'; this.colorbotondos = "green" }
            else if (this.editar == false && this.trabajos == true && this.mostrarcomunicaciones == true || this.editar == false && this.trabajos == false && this.mostrarcomunicaciones == true || this.editar == false && this.trabajos == true && this.mostrarcomunicaciones == false) { this.altura = '80vw'; this.colorbotondos = "normal" }
            else { this.altura = '50vw'; this.colorbotondos = "normal"; }
        },
        togglepresupuestos() {
            this.presupuestos = !this.presupuestos;
        },
        async finVisita() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/finalizar-visita/:id_visita
            //trabajosrealizadostexto trabajospendientestexto
            try {
                const api = new PwgsApi();
                let subidadatos = { trabajos_realizados_cita: this.trabajosrealizadostexto, trabajos_pendientes_cita: this.trabajospendientestexto }
                await api.put("planning-pwgs/" + this.idservicioevento + "/finalizar-visita/" + this.eventoamostrar.visita.idvisitador, subidadatos);
                this.cargar();
                this.visible = false;
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async cargarfestivos() {
            this.events = [];
            this.eventosaeliminar = [];
            const api = new PwgsApi();
            let aux = await api.get('festivos/' + this.anyoactual);
            this.listadofestivos = aux.datos;
            console.log("festivos", aux);




        },
        comprobarfestivos() {
            let auxfestivos = false;
            for (let i = 0; i < this.listadofestivos.length; i++) {
                if (new Date(this.listadofestivos[i].fecha).getFullYear() == this.anyoactual && new Date(this.listadofestivos[i].fecha).getMonth() + 1 == this.mesactual && new Date(this.listadofestivos[i].fecha).getDate() == this.diaactual) {
                    document.getElementsByClassName("vuecal__flex vuecal__title")[0].style.background = '#28a745';
                    this.nombrefestividad = "Festivo " + this.listadofestivos[i].nombre;
                    auxfestivos = true;
                }
            }
            if (auxfestivos == false) {
                document.getElementsByClassName("vuecal__flex vuecal__title")[0].style.background = '#e5e5e5';
                this.nombrefestividad = " ";

            }
        },
        toggleFinalizar() {
            this.finalizar = !this.finalizar;
            if (this.finalizar == true) {
                this.colorbotoncuatro = "green";
            }
            else
            {
                this.colorbotoncuatro = "normal";
            }       
        },
        toggleComunicaciones() {
            this.mostrarcomunicaciones = !this.mostrarcomunicaciones;
            if (this.mostrarcomunicaciones == true) { this.altura = '80vw'; this.colorbotontres = "green" }
            else if (this.mostrarcomunicaciones == false && this.trabajos == true && this.editar == true || this.mostrarcomunicaciones == false && this.trabajos == false && this.editar == true || this.mostrarcomunicaciones == false && this.trabajos == true && this.editar == false) { this.altura = '80vw'; this.colorbotontres = "normal" }
            else { this.altura = '50vw'; this.colorbotontres = "normal"; }
        },
        removerequipo(equip) {
            for (let i = 0; i < this.equiposanyadir.length; i++)
            { if (equip.id_equipo == this.equiposanyadir[i].id_equipo) { this.equiposanyadir.splice(i, 1); } }
        },
        async eliminarVisita(evento) {
            //DELETE  modulos/pwgsapi/index.php/planning-pwgs/eliminar-visita/:id_visita
            const api = new PwgsApi();
            try{
                await api.delete("planning-pwgs/" + this.idvisitaevento + "/eliminar-visita/" + evento.id_visita);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Cita borrada correctamente', life: 2000 });

            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                setTimeout(() => {

                    this.cargareventos();

                }, 1500); 

            }
           
        },
        recargar(){
            setTimeout(() => {

                this.cargareventos();

            }, 1500);
        },
        async cargarpropiedades() {
            console.log('cargaprops');
            //GET /modulos/pwgsapi/index.php/planning-pwgs/137728/formulario-visita
             const api = new PwgsApi();
            this.propiedadescalendario = await api.get('planning-pwgs/0/formulario-visita');
            console.log('cargaprops2');
         },
        async cargarespecialidades() {
            //get  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/especialidades
            const api = new PwgsApi();
            const datos = await api.get("especialidades");
            this.especialidades = datos.datos;
        },
        cargarsplit() {
            this.splitDays = [];
            let subidadatoss = [];           
            let i = 0; 

            for (let operario of Object.entries(this.$props.operarios)) {
                let clicktocall = "window.open('sip:" + operario[1].telefono1 + "','_self'); return false;"
                subidadatoss = { id: operario[1].idoperario, class: operario[1].idoperario, label: operario[1].nombre, split: operario[1].idoperario, color: this.color[i], carga: operario[1].carga_operario, clicktocall: clicktocall, observaciones: operario[1].observaciones_operario, preferente: operario[1].preferente_operario, hide: false, autovisita: operario[1].autovisita }
                this.splitDays.push(subidadatoss);
                i++;
            }

        },
        sumarDias(dia, mes, año, diasASumar) {
            // Dividimos la fecha en día, mes y año
            
            
            // Creamos una nueva fecha usando el constructor Date
            let fechaOriginal = new Date(año, mes - 1, dia); // mes - 1 porque los meses en JS van de 0 (enero) a 11 (diciembre)
            
            // Sumamos los días
            fechaOriginal.setDate(fechaOriginal.getDate() + diasASumar);
            
            // Formateamos la nueva fecha al formato "día-mes-año"
            let nuevoDia = fechaOriginal.getDate().toString().padStart(2, '0');
            let nuevoMes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0'); // +1 porque el mes empieza desde 0
            let nuevoAño = fechaOriginal.getFullYear();
            
            return `${nuevoDia}-${nuevoMes}-${nuevoAño}`;
        },
        async cargareventos() {
            if(this.inicio){
                this.splitDays = [];
                let subidadatoss = [];           
                let i = 0; 
    
                for (let operario of Object.entries(this.$props.operarios)) {
                    let clicktocall = "window.open('sip:" + operario[1].telefono1 + "','_self'); return false;"
                    subidadatoss = { id: operario[1].idoperario, class: operario[1].idoperario, label: operario[1].nombre, split: operario[1].idoperario, color: this.color[i], carga: operario[1].carga_operario, clicktocall: clicktocall, observaciones: operario[1].observaciones_operario, preferente: operario[1].preferente_operario, hide: false, autovisita: operario[1].autovisita }
                    this.splitDays.push(subidadatoss);
                    i++;
                }
                this.inicio = false;
            }   
            if (this.anyoactual.endsWith(")")) {
                this.anyoactual = this.anyoactual.substring(0,this.anyoactual.length-1);
            }
            
            let subidadatos = {};
            if (this.$refs.vuecal.isWeekView == true) {
                var fechanueva = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 6)
                let [dianuevo, mesnuevo, añonuevo] = fechanueva.split('-').map(Number);
                this.diasemanal = dianuevo;
                this.messemanal = mesnuevo
                this.añosemanal = añonuevo;
                subidadatos = { start: this.anyoactual+"-"+this.mesactual+"-"+this.diaactual+"T00:00:00", end: añonuevo+"-"+mesnuevo+"-"+dianuevo+"T23:59:00" };
            }else{
                subidadatos = { start: this.anyoactual+"-"+this.mesactual+"-"+this.diaactual+"T00:00:00", end: this.anyoactual+"-"+this.mesactual+"-"+this.diaactual+"T23:59:00" };
            }
            console.log("anyoactual", this.anyoactual);
            const api = new PwgsApi;  
                       
            this.miseventos = await api.post("planning-pwgs", subidadatos);
          
            this.eventos = [];
            
           
            let background = false;
            let tipo = '';
            let split = '';
            let start = '';
            let end = '';
            for (let evento of Object.entries(this.miseventos)) {
                background = false;
                tipo = evento[1].title;
                if((tipo == "GUARDIA" || tipo == "OCUPADO") && this.$refs.vuecal.isWeekView == true){
                    start = new Date(evento[1].start);
                    end = new Date(evento[1].end);
                    var fechanuevaaux = '';
                    for(let day of evento[1].dow){
                        if(day == '1'){
                            if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                            if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                            let aux = new Date(this.$refs.vuecal.view.startDate).addHours(4).toISOString().split('T')[0] + 'T' + evento[1].start;
                            start = new Date(aux);
                            let auxdos = new Date(this.$refs.vuecal.view.startDate).addHours(4).toISOString().split('T')[0] + 'T' + evento[1].end;
                            end = new Date(auxdos);
                            for (let operario of Object.entries(this.$props.operarios)) {
                                if (operario[1].idoperario == evento[1].id_operario) {
                                    if(evento[1].rendering == "background") { background = true; }
                                    if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" ){evento[1].color = "" }
                                    split = operario[1].idoperario;
                                    
                                    this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split,evento[1].id,evento[1].poblacion,evento[1].cp,evento[1].color_km);   
                                }
                                
                            }
                        }
                        if(day == '2'){
                            if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                            if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                            fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 1);
                            let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                            let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                            start = new Date(aux);
                            let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                            end = new Date(auxdos);
                            for (let operario of Object.entries(this.$props.operarios)) {
                                if (operario[1].idoperario == evento[1].id_operario) {
                                    if(evento[1].rendering == "background") { background = true; }
                                    if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" ){evento[1].color = "" }
                                    split = operario[1].idoperario;
                                    
                                    this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split, evento[1].id, evento[1].poblacion, evento[1].cp, evento[1].color_km);   
                                }
                                
                            }
                        }
                        if(day == '3'){
                            if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                            if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                            fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 2);
                            let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                            let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                            start = new Date(aux);
                            let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                            end = new Date(auxdos);
                            for (let operario of Object.entries(this.$props.operarios)) {
                                if (operario[1].idoperario == evento[1].id_operario) {
                                    if(evento[1].rendering == "background") { background = true; }
                                    if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" ){evento[1].color = "" }
                                    split = operario[1].idoperario;
                                    
                                    this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split, evento[1].id, evento[1].poblacion, evento[1].cp, evento[1].color_km);   
                                }
                                
                            }
                        }
                        if(day == '4'){
                            if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                            if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                            fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 3);
                            let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                            let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                            start = new Date(aux);
                            let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                            end = new Date(auxdos);
                            for (let operario of Object.entries(this.$props.operarios)) {
                                if (operario[1].idoperario == evento[1].id_operario) {
                                    if(evento[1].rendering == "background") { background = true; }
                                    if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" ){evento[1].color = "" }
                                    split = operario[1].idoperario;
                                    
                                    this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split, evento[1].id, evento[1].poblacion, evento[1].cp, evento[1].color_km);   
                                }
                                
                            }
                        }
                        if(day == '5'){
                            if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                            if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                            fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 4);
                            let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                            let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                            start = new Date(aux);
                            let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                            end = new Date(auxdos);
                            for (let operario of Object.entries(this.$props.operarios)) {
                                if (operario[1].idoperario == evento[1].id_operario) {
                                    if(evento[1].rendering == "background") { background = true; }
                                    if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" ){evento[1].color = "" }
                                    split = operario[1].idoperario;
                                    
                                    this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split, evento[1].id, evento[1].poblacion, evento[1].cp, evento[1].color_km);   
                                }
                                
                            }
                        }
                        if(day == '6'){
                            if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                            if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                            fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 5);
                            let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                            let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                            start = new Date(aux);
                            let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                            end = new Date(auxdos);
                            for (let operario of Object.entries(this.$props.operarios)) {
                                if (operario[1].idoperario == evento[1].id_operario) {
                                    if(evento[1].rendering == "background") { background = true; }
                                    if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" ){evento[1].color = "" }
                                    split = operario[1].idoperario;
                                    
                                    this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split, evento[1].id, evento[1].poblacion, evento[1].cp, evento[1].color_km);   
                                }
                                
                            }
                        }
                        if(day == '7'){
                            if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                            if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                            fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 6);
                            let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                            let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                            start = new Date(aux);
                            let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                            end = new Date(auxdos);
                            for (let operario of Object.entries(this.$props.operarios)) {
                                if (operario[1].idoperario == evento[1].id_operario) {
                                    if(evento[1].rendering == "background") { background = true; }
                                    if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" ){evento[1].color = "" }
                                    split = operario[1].idoperario;
                                    
                                    this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split, evento[1].id, evento[1].poblacion, evento[1].cp, evento[1].color_km);   
                                }
                                
                            }
                        }
                    }
                }else{
                    start = new Date(evento[1].start);
                    end = new Date(evento[1].end);
                    /*if (evento[1].title == "FESTIVO") {
                        evento[1].id_operario = operario[1].idoperario;
                        evento[1].start = "01:00";
                        evento[1].end = "23:59";
                        start = evento[1].start;
                        end = evento[1].end;
                    } */
                    if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO") {
                        if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                        if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                        let aux = new Date(this.$refs.vuecal.view.startDate).addHours(4).toISOString().split('T')[0] + 'T' + evento[1].start;
                        start = new Date(aux);
                        let auxdos = new Date(this.$refs.vuecal.view.startDate).addHours(4).toISOString().split('T')[0] + 'T' + evento[1].end;
                        end = new Date(auxdos);
                    }
                    for (let operario of Object.entries(this.$props.operarios)) {
                        if (operario[1].idoperario == evento[1].id_operario) {
                            if(evento[1].rendering == "background") { background = true; }
                            if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" ){evento[1].color = "" }
                            split = operario[1].idoperario;
                            
                            this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split, evento[1].id, evento[1].poblacion, evento[1].cp, evento[1].color_km);   
                        }
                        
                    }
                }
            }


          
        },
         async crearevento(event,tipo) { 
          //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/mover-visita/:id_visita
             const api = new PwgsApi();
            if (tipo == 'arrastra') {
                try {
                    let segundos = ((new Date(event.newDate).getTime() - new Date(event.oldDate).getTime()) / 1000);
                    let subidadatos = { tipo: tipo, segundos: segundos, id_operario: event.newSplit };
                    await api.put("planning-pwgs/" + event.event.idservicio + "/mover-visita/" + event.event.id_visita, subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Evento redimensionado correctamente', life: 2000 });
                 }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                    setTimeout(() => {

                        this.cargareventos();

                    }, 1500);
                }         
             }
             if (tipo == 'redimensiona') {
                try {
                    let segundos = ((new Date(event.event.end).getTime() - new Date(event.originalEvent.end).getTime()) / 1000);
                    let subidadatos = { tipo: tipo, segundos: segundos };
                    await api.put("planning-pwgs/" + event.event.idservicio + "/mover-visita/" + event.event.id_visita, subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Evento movido correctamente', life: 2000 });
                 }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                    setTimeout(() => {

                        this.cargareventos();

                    }, 1500);
                }
             }          
             this.confirmacionvisible = false;
        },
        prueba(event) {
            if (event.view == "week")
              {
               // this.splitDays = [];
                this.editable.drag = false;
                this.editable.resize = false;
              }
            else {
              //  this.splitDays = this.aux;
                this.editable.drag = true;
                this.editable.resize = true;
            }   
            this.$parent.reiniciarfiltros();
        },
        decode_utf8(s) {
            return decodeURIComponent(escape(s)); 
        },
        nuevoevento(titulo, inicio, fin, color, id, description, iconos, idservicio, background, tipo, split, id_visita,poblacion,cp,color_km) {
            
            let mievento = { title: titulo, start: new Date(inicio), end: new Date(fin), color: color, id: id, content: this.decode_utf8(description), fecha: inicio, fechafin: fin, iconos: iconos, idservicio: idservicio, background: background, class: tipo, split: split, draggable: true, deletable: true, id_visita: id_visita, poblacion: poblacion, cp: cp, color_km: color_km };
            if (titulo == "FESTIVO") {
                mievento = { title: titulo, start: inicio, end: fin, color: color, id: id, content: description, fecha: inicio, fechafin: fin, iconos: iconos, idservicio: idservicio, background: background, class: tipo, split: split, draggable: true, deletable: true, id_visita: id_visita, poblacion:poblacion, cp:cp, color_km:color_km };
            }
            else{
                mievento = { title: titulo, start: new Date(inicio), end: new Date(fin), color: color, id: id, content: description, fecha: inicio, fechafin: fin, iconos: iconos, idservicio: idservicio, background: background, class: tipo, split: split, draggable: true, deletable: true, id_visita: id_visita, poblacion: poblacion, cp: cp, color_km: color_km };
            } 
            this.eventos.push(mievento);
        },
        async parteoperarioemail() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-ficha-operario/:id_visita
            const api = new PwgsApi();
            await api.put("planning-pwgs/" + this.idservicioevento + "/enviar-ficha-operario/" + this.eventoamostrar.visita.idvisitador);
        },
        onEventClick(event) {
            this.eventoamostrar = '';
            this.titulo = event.title + " || " + new Date(event.fecha).toLocaleDateString("es-ES", { day: 'numeric' }) + "-" + new Date(event.fecha).toLocaleDateString("es-ES", { month: 'short' }) + "-" + new Date(event.fecha).toLocaleDateString("es-ES", { year: 'numeric' }) + " " + new Date(event.fecha).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' }) + "-" + new Date(event.fechafin).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' });
            this.infoevento = event.content;
            this.trabajos = false;
            this.fechainicioeditar = event.fecha;
            this.fechafineditar = event.fechafin;
            this.altura = '50vw';
            if (event.title != 'GUARDIA' && event.title != 'OCUPADO' && event.title != 'BLOQUEADO' && event.title != 'VACACIONES' && event.title != 'RESERVA') {
                this.visible = true;
            }            this.editar = false;
            this.finalizar = false;
            this.colorbotondos = "normal";
            this.colorboton = "normal";
            this.colorbotontres = "normal";
            this.colorbotoncuatro = "normal"; 
            this.cargarvisitaevento(event.idservicio, event.id);
        },
         async smsoperario() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-sms-operario/:id_visita
            const api = new PwgsApi();
            try{
                await api.put("planning-pwgs/" + this.idservicioevento + "/enviar-sms-operario/" + this.eventoamostrar.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Sms enviado al operario correctamente', life: 2000 });
            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async smsasegurado() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-sms-asegurado/:id_visita
            const api = new PwgsApi();
            try {
                await api.put("planning-pwgs/" + this.idservicioevento + "/enviar-sms-asegurado/" + this.eventoamostrar.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Sms enviado al asegurado correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async steasegurado() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-ste-firma/:id_visita
            const api = new PwgsApi();
            try{
                await api.put("planning-pwgs/" + this.$props.id + "/enviar-ste-firma/" + this.eventoamostrar.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Ste enviado correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async cargarvisitaevento(idvisita, idevento) {
            //GET  modulos/pwgsapi/index.php/servicios/:id/visitas
            const api = new PwgsApi();
            let datos; 
            if (idvisita){
                datos = await api.get('servicios/' + idvisita + '/visitas');
          
            for (let i = 0; i < datos.length; i++) {
                if (datos[i].visita.datos.idvisitador == idevento) {
                    this.eventoamostrar = datos[i];
                    this.operariosingular = this.eventoamostrar.visita.operarios_idoperario;
                }
                }
            }
        },
        splitcheck() {
            if (this.$refs.vuecal.isDayView == false) {
                this.splitDays = [];
            }
          //  else { this.splitDays = this.aux }
        },
        async pedidosservicio() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/pedidos
        },
        abrirventana() {
            let urlaux = localStorage.getItem('direccion');
            this.url = this.eventoamostrar.fichas_operario.generico.slice(5);
            window.open("https://"+urlaux+ this.url);
        },
       async confirmarvisita(event) {
           // modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/confirmar-visita/:id_visita
            let fechainicio = new Date(event.visita.fecha).toLocaleDateString('es-ES');
            let fechafin = new Date(event.visita.fecha_fin).toLocaleDateString('es-ES');
            let formatomini = new Date(event.visita.fecha_inicio).getMinutes();
            if (formatomini < 10) formatomini = "0" + formatomini;
            let formatominf = new Date(event.visita.fecha_fin).getMinutes();
            if (formatominf < 10) formatominf = "0" + formatominf;
            let subidadatos = { fecha_cita_inicio: fechainicio, fecha_cita_fin: fechafin, hora_cita_fin: event.visita.hora_fin, hora_cita_inicio: event.visita.hora };
            const api = new PwgsApi();
           await api.put('planning-pwgs/' + this.idservicioevento + '/confirmar-visita/' + event.visita.idvisitador, subidadatos);
        },
        cambiartexto(texto) {
            this.textoprueba = this.textoprueba + texto;
        },
       async modificarVisita(evento, fechini, fechfin, descrevento, modificar, prioritaria) {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/modificar-visita/:id_visita
            let fechainicio = new Date(fechini).toLocaleDateString('es-ES');
            let fechafin = new Date(fechfin).toLocaleDateString('es-ES');
            let formatohorai = new Date(fechini).getHours();
            if (formatohorai < 10) formatohorai = "0" + formatohorai;
            let formatohoraf = new Date(fechfin).getHours();
            if (formatohoraf < 10) formatohoraf = "0" + formatohoraf;
            let formatomini = new Date(fechini).getMinutes();
            if (formatomini < 10) formatomini = "0" + formatomini;
            let formatominf = new Date(fechfin).getMinutes();
            if (formatominf < 10) formatominf = "0" + formatominf;
            let horainicio = formatohorai + ":" + formatomini + ":00";
            let horafin = formatohoraf + ":" + formatominf + ":00";
            const api = new PwgsApi();
            let subidadatos = { fecha_cita_inicio: fechainicio, fecha_cita_fin: fechafin, hora_cita_inicio: horainicio, hora_cita_fin: horafin, descripcion_cita: descrevento, prioritaria_cita: prioritaria, no_modificar_cita: modificar };
           try {
               await api.put("planning-pwgs/" + this.idservicioevento + "/modificar-visita/" + evento.visita.idvisitador, subidadatos);
               this.visible = false;
               this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
           }
           catch (error) {
               this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
               setTimeout(() => {
                
                   this.cargareventos();

               }, 2000);
            }         
        },
        sacarmes() {
            console.log('cargames');
            this.anyoactual = this.$refs.vuecal.viewTitle.substr(this.$refs.vuecal.viewTitle.length - 5);
            let aux = new Date(this.$refs.vuecal.view.startDate).getDate();
            if (aux < 10) { aux = "0" + aux; }
            this.diaactual = aux;
            if (this.$refs.vuecal.viewTitle.includes("Enero")) {
                this.mesactual = "01";
            }
            if (this.$refs.vuecal.viewTitle.includes("Febrero")) {
                this.mesactual = "02";
            }
            if (this.$refs.vuecal.viewTitle.includes("Marzo")) {
                this.mesactual = "03";
            }
            if (this.$refs.vuecal.viewTitle.includes("Abril")) {
                this.mesactual = "04";
            }
            if (this.$refs.vuecal.viewTitle.includes("Mayo")) {
                this.mesactual = "05";
            }
            if (this.$refs.vuecal.viewTitle.includes("Junio")) {
                this.mesactual = "06";
            }
            if (this.$refs.vuecal.viewTitle.includes("Julio")) {
                this.mesactual = "07";
            }
            if (this.$refs.vuecal.viewTitle.includes("Agosto")) {
                this.mesactual = "08";
            }
            if (this.$refs.vuecal.viewTitle.includes("Septiembre")) {
                this.mesactual = "09";
            }
            if (this.$refs.vuecal.viewTitle.includes("Octubre")) {
                this.mesactual = "10";
            }
            if (this.$refs.vuecal.viewTitle.includes("Noviembre")) {
                this.mesactual = "11";
            }
            if (this.$refs.vuecal.viewTitle.includes("Diciembre")) {
                this.mesactual = "12";
            }
            console.log('cargames2');
        },
    },
    activated() {
     
    },
    mounted() {
        this.cargarpropiedades();
        setTimeout(() => {

        this.sacarmes();
        this.cargar();

        }, 5000); 
        this.cargarfestivos();

    },
  
    watch: {
        diaactual() {
            console.log("diaactual", this.diaactual);            

            this.cargareventos();

           // this.$parent.reiniciaroperarios(); 
            console.log("operarioseleccionados", this.$props.operariosseleccionados);
            

            console.log("splitdays123", this.splitDays.length);

        
            this.comprobarfestivos();

            for (let i = 0; i < this.splitDays.length; i++) {
                this.splitDays[i].hide = true;
                if (this.$props.operariosseleccionados.length < 1) {
                    this.splitDays[i].hide = false;
                }
                for (let j = 0; j < this.$props.operariosseleccionados.length; j++) {
                    if (this.splitDays[i].id == this.$props.operariosseleccionados[j]) {
                        console.log("operarioseleccionados11");
                        this.splitDays[i].hide = false;
                    }
                }
            }
        },
        descripcionvisitas() {
            if (this.descripcionvisitas.length < 30) this.miclase = "p-invalid";
            else this.miclase = "";
        },
        operariosseleccionados(value) {
            if(value == ''){
                this.vistaactiva = "day";
                this.cargareventos();
            }
            for (let i = 0; i < this.splitDays.length; i++){
                  this.splitDays[i].hide = true;
                  if (this.$props.operariosseleccionados.length < 1 && this.$props.cambios == false) { this.splitDays[i].hide = false;  }
                    for (let j = 0; j < this.$props.operariosseleccionados.length; j++){
                        if (this.splitDays[i].id == this.$props.operariosseleccionados[j])
                        {
                            this.splitDays[i].hide = false;
                         }
                  }
              }
    
        }
    }
})
</script>
<style>
.vuecal__cell-events-count{
    color: black;
    background-color: yellow;
    height: 15px;
};
.vuecal__title button {
    color: rgb(0, 0, 0);
}
.vuecal--month-view .vuecal__cell {
    height: 70px;
}
.vuecal__today-btn {
    font-size: 0.8em;
    border-radius: 5px;
    color: rgb(0, 0, 0);
}
.vuecal__today-btn:hover {
    background-color: rgb(0, 105, 217);
    border-radius: 4px;
    color: rgb(255, 255, 255);
}
.table td, .table th {
	padding: .75rem;
	vertical-align: middle;
	border-top: 0px solid #dee2e6;
}
.vuecal__split-days-headers{
    text-align: center;
    height: 5em !important;
}
.vuecal__title-bar{
    margin-bottom:2%;
}
.vuecal__body{
    margin-top:2%;
}
.operarioseleccionado{
    width:70%;
}
.GUARDIA{
      background:
    #fff7f0 
    repeating-linear-gradient(
      -45deg,
      rgba(255, 87, 87, 0.25),
      rgba(255, 87, 87, 0.25) 5px,
      rgba(255, 255, 255, 0) 5px,
      rgba(255, 255, 255, 0) 15px
    ) !important;
    color:rgb(216, 27, 96) !important;    
}
.OCUPADO {
    background:
        #fff7f0 
    repeating-linear-gradient(
        -45deg,
            rgba(102, 126, 231, 0.25),
            rgba(102, 126, 231, 0.25), 5px,
            rgba(255, 255, 255, 0) 5px,
            rgba(255, 255, 255, 0) 15px) 
            !important;
    color: rgb(30, 27, 216) !important;
}
.BLOQUEADO {
    background:
        #fff7f0 repeating-linear-gradient(
            -45deg,
            rgba(71, 98, 104, 0.25),
            rgba(71, 98, 104, 0.25), 5px,
            rgba(255, 255, 255, 0) 5px,
            rgba(255, 255, 255, 0) 15px) !important;
    color: rgb(71, 98, 104) !important;
}
.VACACIONES {
    background:
        #fff7f0 repeating-linear-gradient(-45deg,
            rgba(0, 204, 0, 0.25),
            rgba(0, 204, 0, 0.25), 5px,
            rgba(255, 255, 255, 0) 5px,
            rgba(255, 255, 255, 0) 15px) !important;
    color: rgb(18, 110, 18) !important;
}
.vuecal__cell-split{
    border: 0.5px solid;
    border-color:lightgray;
}
.vuecal__flex .vuecal__cell-content{
    display:block;
}
.inputfecha{
    width: 10rem !important;
}
</style>
